<template>
    <div>
        <el-table stripe border v-loading="loading" :data="dataList" >
            <el-table-column label="商户编号" prop="merchantNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="商户名称" prop="merchantName" min-width="120" :show-overflow-tooltip="true"/>
            <!-- <el-table-column label="姓名" prop="contactName" min-width="120" :show-overflow-tooltip="true"/> -->
            <el-table-column label="商户状态" prop="merStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.merchantStatus=='1'?'审核成功' : ''}}
                </template>
            </el-table-column>
            <el-table-column label="开通时间" prop="merCreateTime" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="变更类型" prop="changeType" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.changeType | changeTypeFM}}
                </template>
            </el-table-column>
            <el-table-column label="提交时间" prop="changeCreateTime" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="审核状态" prop="changeCheckStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.changeCheckStatus | changeCheckStatusFM}}
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="text"
                               @click="handleDetail(scope.row)"
                               v-permission="'AGENT_INFO_DETAIL'">详情</el-button>
                </template>
            </el-table-column>
        </el-table>

        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
    </div>
</template>

<script>
    import {MerchantApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false
            }
        },
        mounted() {
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await MerchantApi.merChangeApply.queryPage(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            handleDetail(row){
                if(row.changeType == 'STATUS'){
                    this.$router.push({
                        name:'StatusChangeApply',
                        query:{
                            applyNo: row.applyNo
                        }
                    })
                } else if(row.changeType == 'RATE'){
                    this.$router.push({
                        name:'RateChangeDetail',
                        query:{
                            applyNo: row.applyNo,
                            merchantNo: row.merchantNo,
                            merchantName: row.merchantName,
                            
                        }
                    })
                }else if(row.changeType == 'SETTLE_CARD'){
                    this.$router.push({
                        name:'SettleChangeDetail',
                        query:{
                            applyNo: row.applyNo
                        }
                    })
                }
            }
        }
    };
</script>

<style scoped>

</style>
